.overlay {
  position: absolute;
  background: rgba(57, 57, 57, 0.5);

  /* center overlay text */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  margin-top: 50px;
}

.overlay_1 {
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1rem;
}
